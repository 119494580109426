<template>
  <div id="docModal">
    <div class="top-right">
      <button type="button" @click="closeModal">
        X
      </button>
    </div>
    <div>
      <div v-if="isDocumentVisible">
        <DocumentPDF
            :assetId="assetId"
            :resourceDocumentId="resourceDocumentId">
        </DocumentPDF>
      </div>
      <div v-else class="no-document">
        <div class="no-document-title">Sorry</div>
        <div class="no-document-detail">Document is not active.</div>
      </div>
    </div>
  </div>
</template>

<script>

import DocumentPDF from './DocumentPdf.vue';
import { getSessionItem, clearSessionItem } from '../../utils';

export default {
  name: 'DocumentModal',
  components: {
    DocumentPDF,
  },
  props: ['assetId', 'resourceDocumentId'],
  computed: {
    isDocumentVisible() {
      return this.assetId && this.resourceDocumentId;
    },
  },
  methods: {
    async closeModal() {
      const docId = getSessionItem('docId');
      this.$modal.hide('DocumentModal');

      if (docId !== this.resourceDocumentId) {
        window.location = `${window.location.href.substring(0, window.location.href.lastIndexOf('resources'))}resources/d/${docId}`;
      } else {
        clearSessionItem('docId');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  #videoModal {
    padding: 2em;
    background-color: white;
  }
  button {
    outline: none;
    top: -2em;
    right: -0.5em;
    height: 50%;
    display: block;
    float: right;
    width: 10%;
    background: $LincolnOrange;
    text-align: center;
    margin-left: -1px;
    position: relative;
    line-height: 1.5em;
    font-size: 1rem;
    text-transform: uppercase;
    color: #fefefe;
    transition: all 0.35s ease;
    border: 3px solid #fff;

    &:hover {
        color: black;
    }
  }

  .no-document{
    margin: 35px;
    background-color: #151515;
    color: white;
    padding-top: 200px;
    padding-bottom: 200px;
    padding-left: 30%;
    border-radius: 1rem;

    .no-document-title {
      font-size: 4rem;
    }

    .no-document-detail {
      font-size: 2rem;
    }
  }

</style>
