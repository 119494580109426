var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _vm.isPdfVisible
      ? _c("div", [
          _c("iframe", {
            attrs: { src: _vm.src, width: "100%", height: _vm.iframeHeight },
          }),
        ])
      : _vm._e(),
    !_vm.isPdfVisible
      ? _c("div", { staticClass: "pdf-placeholder" }, [
          _c("div", { staticClass: "pdf-placeholder-text" }, [
            !_vm.isLoading
              ? _c("div", [_vm._v("PDF Document Unavailable")])
              : _vm._e(),
            _vm.isLoading
              ? _c(
                  "div",
                  [
                    _c("font-awesome-icon", {
                      staticClass: "fa-spin loading",
                      attrs: { icon: ["fas", "cog"], size: "2x" },
                    }),
                    _c("span", { staticClass: "loading-text" }, [
                      _vm._v("Loading..."),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }