<template>
  <div id="videoModal">
    <div class="top-right">
      <button type="button" @click="closeModal">
        X
      </button>
    </div>
    <div class="video-frame" style="padding:56.25% 0 0 0;position:relative;">
      <iframe :src="'https://player.vimeo.com/video/' + videoId + '?title=0&byline=0&portrait=0'"
              style="position:absolute;top:0;left:0;width:100%;height:100%;"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>

import { getSessionItem, clearSessionItem } from '../../../utils';

export default {
  name: 'videoModal',
  props: ['videoId'],
  methods: {
    closeModal() {
      const docId = getSessionItem('docId');
      this.$modal.hide('VideoModal');

      if (docId !== this.resourceDocumentId) {
        window.location = `${window.location.href.substring(0, window.location.href.lastIndexOf('resources'))}resources/d/${docId}`;
      } else {
        clearSessionItem('docId');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  #videoModal {
    padding: 2em;
    background-color: white;
  }
  button {
    outline: none;
    top: -2em;
    right: -2em;
    height: 50%;
    display: block;
    float: right;
    width: 10%;
    background: $LincolnOrange;
    text-align: center;
    margin-left: -1px;
    position: relative;
    line-height: 1.5em;
    font-size: 1rem;
    text-transform: uppercase;
    color: #fefefe;
    transition: all 0.35s ease;
    border: 3px solid #fff;
  }
</style>
