<script>
import VideoModal from '../components/resources/videos/VideoModal.vue';
import DocumentModal from '../components/resources/DocumentModal.vue';
import apiManager from '../api';


export default {
  methods: {
    async showVideoModal(resourceVideoId) {
      this.$store.dispatch('analyticStore/updateLastNavTime');
      const analytic = {
        analyticType: 'VideoView',
        resourceId: resourceVideoId,
        userId: this.$store.getters['userStore/userId'],
      };
      this.$store.dispatch('analyticStore/add', analytic);

      const { data } = await apiManager.resourceVideo.get(resourceVideoId);
      this.$modal.show(VideoModal, { videoId: data.videoId }, {
        name: 'VideoModal',
        adaptive: true,
        height: 'auto',
        width: '70%',
        scrollable: true,
      });
    },
    async showDocumentModal(assetId, resourceDocumentId) {
      this.$store.dispatch('analyticStore/updateLastNavTime');
      const analytic = {
        analyticType: 'DocumentView',
        resourceId: resourceDocumentId,
        userId: this.$store.getters['userStore/userId'],
      };
      this.$store.dispatch('analyticStore/add', analytic);

      this.$modal.show(DocumentModal, { assetId, resourceDocumentId }, {
        name: 'DocumentModal',
        adaptive: true,
        height: 'auto',
        width: '70%',
        scrollable: true,
        clickToClose: false,
      });
    },
  },
};
</script>
