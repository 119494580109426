<template>
  <div class="wrapper">
    <div v-if="isPdfVisible">
      <iframe
        :src="src"
        width="100%" :height="iframeHeight">
      </iframe>
    </div>
    <div v-if="!isPdfVisible" class="pdf-placeholder">
      <div class="pdf-placeholder-text">
        <div v-if="!isLoading">PDF Document Unavailable</div>
        <div v-if="isLoading">
          <font-awesome-icon
            :icon="['fas', 'cog']"
            size="2x"
            class="fa-spin loading"
          />
          <span class="loading-text">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DocumentPdf',
  data() {
    return {
      src: '',
      isLoading: true,
      windowHeight: 0,
    };
  },
  props: {
    assetId: {
      type: String,
      required: true,
    },
    resourceDocumentId: {
      type: String,
      required: true,
    },
  },
  computed: {
    isPdfVisible() {
      return !this.isLoading && this.src !== '';
    },
    iframeHeight() {
      return `${this.windowHeight - 200}px`;
    },
  },
  methods: {
    async getSourcePdf() {
      this.isLoading = true;
      const asset = await this.$store.dispatch(
        'assetStore/downloadAsset',
        this.assetId,
      );
      if (asset) {
        this.src = window.URL.createObjectURL(new Blob([asset.data], { type: 'application/pdf' }));
      }
      this.isLoading = false;
    },
    onResizeWindow() {
      this.windowHeight = document.documentElement.clientHeight;
    },
  },
  async created() {
    this.onResizeWindow();
    window.addEventListener('resize', this.onResizeWindow);
    await this.getSourcePdf();
  },
  destroyed() {
    window.removeEventListener('resize', this.onResizeWindow);
  },
};

</script>

<style lang="scss" scoped>
.wrapper {
  margin: 30px;
}

.pdf-wrapper{
  border: 1px solid #555555;
}

.nav-control-wrapper{
  margin-bottom: 10px;
}

.pdf-placeholder{
  background-color: #000000;
  color: #ffffff;
  font-size: 2em;
  height: 600px;
}

.pdf-placeholder-text {
  text-align: center;
  padding-top: 280px;
}

.loading-text {
  padding-left:0.25em;
  font-size: 2em;
  padding-top: 280px;
}

.button {
  padding-left:4px !important;
  padding-right:4px !important;

  .icon {
    color: rgb(1, 94, 1);
    opacity: 0.7;
    background-image: radial-gradient(at center, white 40%, transparent 40%);
    border-radius: 50%;
    transition: all 0.3s;


    &:hover {
      opacity: 1;
      cursor: pointer;
    }

    &.disable {
      color: $LincolnGrayDark;
      pointer-events: none;
    }
  }

  .download {
    color: navy !important;
    padding-left:15px !important;
  }
}


</style>
